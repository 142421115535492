@use '@angular/material' as mat;

$safety-palette: (
  50 : #e4eeef,
  100 : #bbd4d7,
  200 : #8eb7bc,
  300 : #619aa1,
  400 : #3f858d,
  500 : #1d6f79,
  600 : #1a6771,
  700 : #155c66,
  800 : #11525c,
  900 : #0a4049,
  A100 : #80eaff,
  A200 : #4de1ff,
  A400 : #1ad9ff,
  A700 : #00d4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$safety-dark-theme-palette: (
  50 : #ffffff,
  100 : #ffffff,
  200 : #ffffff,
  300 : #ffffff,
  400 : #ffffff,
  500 : #ffffff,
  600 : #ffffff,
  700 : #ffffff,
  800 : #ffffff,
  900 : #ffffff,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$safety-light-theme-palette: (
  50 : #e8e8e8,
  100 : #c6c6c6,
  200 : #a0a0a0,
  300 : #7a7a7a,
  400 : #5e5e5e,
  500 : #414141,
  600 : #3b3b3b,
  700 : #323232,
  800 : #2a2a2a,
  900 : #1c1c1c,
  A100 : #f07777,
  A200 : #eb4949,
  A400 : #ff0202,
  A700 : #e70000,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$safety-palette-new: (
  50 : #f9fbe0,
  100 : #f1f5b3,
  200 : #e8ef80,
  300 : #dfe84d,
  400 : #d8e326,
  500 : #d1de00,
  600 : #ccda00,
  700 : #c6d500,
  800 : #c0d100,
  900 : #b5c800,
  A100 : #fdfff1,
  A200 : #f8ffbe,
  A400 : #f2ff8b,
  A700 : #efff71,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


//$safety-color: rgba(0, 96, 100, 1);
$safety-color-grey: rgba(188, 185, 164, 0.3);
$safety-color: rgba(209, 222, 0, 1);

mat.$light-theme-background-palette: (
  status-bar: map_get(mat.$grey-palette, 300),
  app-bar: map_get(mat.$grey-palette, 100),
  background: map_get(mat.$grey-palette, 300),
  hover: rgba(black, 0.04), // TODO(kara): check style with Material Design UX
  card: #eeeeee,
  dialog: #eeeeee,
  disabled-button: rgba(black, 0.12),
  raised-button: #eeeeee,
  focused-button: rgba(black, 0.06),
  selected-button: map_get(mat.$grey-palette, 300),
  selected-disabled-button: map_get(mat.$grey-palette, 400),
  disabled-button-toggle: map_get(mat.$grey-palette, 200),
);
//// Light Theme
//$theme-light-primary: mat.define-palette($safety-palette, 500, 500, 500);
//$theme-light-accent: mat.define-palette($safety-palette, A400, A100, A700);
//$theme-light-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
//$theme-light: mat.define-light-theme($theme-light-primary, $theme-light-accent, $theme-light-warn);
//
//// Plain Theme
//$theme-plain-primary: mat.define-palette($safety-palette, 500, 500, 500);
//$theme-plain-accent: mat.define-palette(mat.$light-green-palette, A400, A100, A700);
//$theme-plain-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
//$theme-plain: mat.define-light-theme($theme-plain-primary, $theme-plain-accent, $theme-plain-warn);
//
//// Dark Theme
//$theme-dark-primary: mat.define-palette($safety-palette, 500, 500, 500);
//$theme-dark-accent: mat.define-palette(mat.$cyan-palette, A400, A100, A700);
//$theme-dark-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
//$theme-dark: mat.define-dark-theme($theme-dark-primary, $theme-dark-accent, $theme-dark-warn);

//// Light Theme
//$theme-light-primary: mat.define-palette($safety-palette-new, 500, 500, 500);
////$theme-light-accent: mat.define-palette($safety-palette-new, A400, A100, A700);
//$theme-light-accent: mat.define-palette($safety-palette-new, 500, 500, 500);
//$theme-light-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
//$theme-light: mat.define-light-theme($theme-light-primary, $theme-light-accent, $theme-light-warn);
//
//// Plain Theme
//$theme-plain-primary: mat.define-palette($safety-palette-new, 500, 500, 500);
//$theme-plain-accent: mat.define-palette(mat.$light-green-palette, A400, A100, A700);
//$theme-plain-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
//$theme-plain: mat.define-light-theme($theme-plain-primary, $theme-plain-accent, $theme-plain-warn);
//
//// Dark Theme
//$theme-dark-primary: mat.define-palette($safety-palette-new, 500, 500, 500);
////$theme-dark-accent: mat.define-palette($safety-palette-new, A400, A100, A700);
//$theme-dark-accent: mat.define-palette($safety-palette-new, 500, 500, 500);
//$theme-dark-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
//$theme-dark: mat.define-dark-theme($theme-dark-primary, $theme-dark-accent, $theme-dark-warn);

// Light Theme
$theme-light-primary: mat.define-palette($safety-light-theme-palette, 500, 500, 500);
//$theme-light-accent: mat.define-palette($safety-palette-new, A400, A100, A700);
$theme-light-accent: mat.define-palette($safety-light-theme-palette, 500, 500, 500);
$theme-light-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
$theme-light: mat.define-light-theme($theme-light-primary, $theme-light-accent, $theme-light-warn);

// Plain Theme
$theme-plain-primary: mat.define-palette($safety-palette-new, 500, 500, 500);
$theme-plain-accent: mat.define-palette(mat.$light-green-palette, A400, A100, A700);
$theme-plain-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
$theme-plain: mat.define-light-theme($theme-plain-primary, $theme-plain-accent, $theme-plain-warn);

// Dark Theme
$theme-dark-primary: mat.define-palette($safety-dark-theme-palette, 500, 500, 500);
//$theme-dark-accent: mat.define-palette($safety-palette-new, A400, A100, A700);
$theme-dark-accent: mat.define-palette($safety-dark-theme-palette, 500, 500, 500);
$theme-dark-warn: mat.define-palette(mat.$red-palette, A400, A100, A700);
$theme-dark: mat.define-dark-theme($theme-dark-primary, $theme-dark-accent, $theme-dark-warn);


// Available Themes (keys are template class names)
$saf-themes: (
  theme-light: $theme-light,
  theme-plain: $theme-plain,
  theme-dark: $theme-dark,
);


$saf-xtiny: 'max-width: 359px';
$saf-tiny: 'max-width: 479px';
$saf-xsmall: 'max-width: 599px';
$saf-small: 'max-width: 959px';
$saf-medium: 'max-width: 1279px';
$saf-large: 'max-width: 1919px';
$saf-xlarge: 'min-width: 1920px';

$saf-half-table-width: 600px;
$saf-max-content-width: 960px;
//$saf-max-content-table-width: 1280px;
$saf-max-content-table-width: 100%;

$saf-color-red: mat.get-color-from-palette(mat.$red-palette, A400);
$saf-color-red-text: map-get(map-get(mat.$red-palette, contrast), A400);

$saf-color-orange: mat.get-color-from-palette(mat.$orange-palette, A400);
$saf-color-orange-text: mat.get-color-from-palette(map-get(mat.$orange-palette, contrast), A400);

$saf-color-yellow: mat.get-color-from-palette(mat.$yellow-palette, A400);
$saf-color-yellow-text: mat.get-color-from-palette(map-get(mat.$yellow-palette, contrast), A400);

$saf-color-green: mat.get-color-from-palette(mat.$light-green-palette, A400);
$saf-color-green-text: mat.get-color-from-palette(map-get(mat.$light-green-palette, contrast), A400);

$saf-color-grey: mat.get-color-from-palette(mat.$gray-palette, A400);
$saf-color-grey-text: mat.get-color-from-palette(map-get(mat.$gray-palette, contrast), A400);

@mixin saf-core-theme($theme) {
  $primary: map-get($theme, primary);
  $primaryHover: map-get($theme, background);
  $background: if(map-get($theme, is-dark), map-get($theme, background), mat.$light-theme-background-palette);
  $matListItem: if(map-get($theme, is-dark), map-get($theme, background), mat.$light-theme-background-palette);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  saf-login,
  saf-logout,
  saf-page-not-found,
  saf-terminal {
    background: mat.get-color-from-palette($background, background);
  }

  ::-webkit-scrollbar {
    width: 10px !important;
  }

  saf-breadcrumbs {
    .breadcrumb {
      color: mat.get-color-from-palette($primary, default-contrast);
    }
  }

  saf-audit-details-analyses {
    .cell {
      background-color: mat.get-color-from-palette($foreground, divider);
    }
  }

  saf-hazard-group-list,
  saf-hazard-assessment-classification-dialog {
    .group-image {
      filter: invert(if($is-dark-theme, 1, 0));
    }
  }

  .pagination-toolbar {
    background: mat.get-color-from-palette($background, card);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    place-items: center;
    min-height: 56px;
    //border-bottom-left-radius: 10px;
    //border-bottom-right-radius: 10px;

    .refresh-button {
      color: mat.get-color-from-palette($foreground, secondary-text);
      margin-right: 8px;
    }
  }

  .mat-table {
    .mat-row {
      &:hover {
        background: mat.get-color-from-palette($background, hover);
      }

      &.selectable {
        cursor: pointer;
      }
    }

    .mat-cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 0;
    }

    .mat-header-cell {
      font-weight: bold;
      border-bottom: 2px solid;
      font-size: 14px;

    }

    .mat-cell,
    .mat-header-cell,
    .mat-footer-cell {
      padding: 0 8px;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }

      @media (max-width: 599px) {
        &:first-child {
          padding-left: 16px;
        }

        &:last-child {
          padding-right: 16px;
        }
      }

      > .mat-icon {
        line-height: 24px;
        font-size: 20px;
        height: 24px;
        width: 24px;
        text-align: center;
        vertical-align: middle;
      }

      > .mat-icon-button {
        width: 32px;
        height: 32px;
        line-height: 32px;

        .mat-icon {
          font-size: 20px;
          line-height: 20px;
          height: 20px;
          width: 20px;
          margin-top: -4px;
        }
      }
    }
  }

  .mat-list {
    &.selectable {
      .mat-list-item {
        cursor: pointer;

        &:hover,
        &:focus {
          background: mat.get-color-from-palette($background, hover);
          color: map_get($primary, default);
        }
      }
    }

    //&.selectable-subheader {
    //  .mat-subheader {
    //    cursor: pointer;
    //
    //    &:hover,
    //    &:focus {
    //      background: mat-color($background, hover);
    //      color: map_get($primary, default);
    //    }
    //  }
    //}
  }

  .mat-form-field-outline {
    background-color: mat.get-color-from-palette($background, card);
  }

  .select-input {
    cursor: pointer;
  }

  .wysiwyg-content p {
    margin: 0;
  }

  .mat-checkbox {
    &.wrap-label {
      .mat-checkbox-label {
        white-space: normal;
        line-height: 18px;
      }
    }
  }

  .ql-size-small {
    font-size: 0.75em;
  }

  .ql-size-large {
    font-size: 1.5em;
  }

  .ql-size-huge {
    font-size: 2.5em;
  }

  .saf-background {
    &-green {
      background-color: $saf-color-green !important;
      color: $saf-color-green-text !important;
    }

    &-yellow {
      background-color: $saf-color-yellow !important;
      color: $saf-color-yellow-text !important;
    }

    &-orange {
      background-color: $saf-color-orange !important;
      color: $saf-color-orange-text !important;
    }

    &-red {
      background-color: $saf-color-red !important;
      color: $saf-color-red-text !important;
    }

    &-grey {
      background-color: $saf-color-grey !important;
      color: $saf-color-grey-text !important;
    }
  }

  .saf-color {
    &-green {
      color: $saf-color-green !important;
    }

    &-yellow {
      color: $saf-color-yellow !important;
    }

    &-orange {
      color: $saf-color-orange !important;
    }

    &-red {
      color: $saf-color-red !important;
    }

    &-grey {
      color: $saf-color-grey !important;
    }
  }

  .saf-chip {
    padding: 5px 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    color: if($is-dark-theme, rgb(255,255,255), rgb(77, 77, 77)) !important;
    background-color: $safety-color-grey;
  }

  .saf-chip-filter {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    color: if($is-dark-theme, rgb(255,255,255), rgb(77, 77, 77)) !important;
    background-color: $safety-color-grey;
    cursor: pointer;
    user-select: none;
    white-space:nowrap;
    .check {
      height: 18px;
      width: 18px;
      font-size: 18px;
      margin-right: 5px;
    }
  }

  .parentTree {
    background-color: if($is-dark-theme, rgb(128,128,128), rgb(220, 220, 220));
    border-radius: 10px;
  }

  //.widget-header:hover {
  //  -webkit-box-shadow: inset 0px 0px 0px 2px if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
  //  -moz-box-shadow: inset 0px 0px 0px 2px if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
  //  box-shadow: inset 0px 0px 0px 2px if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
  //}
  mat-list-item:hover, mat-expansion-panel-header:hover {
    background-color: if($is-dark-theme, #303030, #807e7e) !important;
  }
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: 0 0 7px 0 #0003, 0 0 2px #00000024, 0 0 5px #0000001f;
    //box-shadow: none;
    //background-color: if($is-dark-theme, rgb(128,128,128), rgb(220, 220, 220));
  }

  .widget-title:hover {
    //color: if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
    //color: rgb(0, 0, 0);
    //cursor: pointer;
    user-select: none;
  }
  .widget-navigation-title:hover {
    color: if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
  }
  .accident:hover,
  .administration:hover,
  .observation:hover,
  .audit:hover,
  .hazardAssessment:hover,
  .instruction:hover,
  .externalCompany:hover,
  .externalCompanies:hover,
  .measure:hover,
  .schooling:hover,
  .qualification:hover,
  .screening:hover,
  .maintenance:hover,
  .inspection:hover,
  .measurement:hover,
  .accidentBook:hover,
  .medical:hover,
  .userAdministration,
  .hazardousSubstance:hover,
  .checklist:hover {
    //border: 2px if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0)) solid !important;
  }

  .sticky {
    padding-top: 16px;
    position: fixed;
    box-sizing: border-box;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background-color: if($is-dark-theme, rgb(48, 48, 48), rgb(224, 224, 224));
  }
  .stickyUp {
    padding-top: 16px;
    position: fixed;
    box-sizing: border-box;
    top: 0;
    z-index: 100;
    width: 100%;
    background-color: if($is-dark-theme, rgb(48, 48, 48), rgb(224, 224, 224));
  }
  .stickyHeader {
    position: -webkit-sticky; /* Safari */
    position: sticky !important;
    top: 0 !important;
    background-color: if($is-dark-theme, rgb(66, 66, 66), rgb(238, 238, 238));
    z-index: 9999 !important;
  }

  .navigationIconContainer:hover {
    border: 3px solid if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
    border-radius: 27px;
    background: if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
    // box-shadow: inset 20px 20px 20px 20px #414141;
  }

  .navigationAppIcon:hover {
    border: 2px solid if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
    border-radius: 27px;
    background: if($is-dark-theme, rgb(255, 255, 255), rgb(0, 0, 0));
  }

  .mat-paginator-sticky {
    bottom: 0;
    position: sticky;
    z-index: 10;
    border-top: 2px solid if($is-dark-theme, rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.54));
  }

  //TODO: theme anpassen

  .mat-calendar-body-selected {
    color: if($is-dark-theme, rgb(0, 0, 0), rgb(255, 255, 255));;
  }

  ::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: rgba(224, 26, 26, 0.7);
  }
}

.stickyDialogHeader {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

@mixin saf-autofill-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: mat.get-color-from-palette($foreground, text);
    -webkit-box-shadow: 0 0 0 1000px mat.get-color-from-palette($background, card) inset !important;
  }
}

@mixin saf-scrollbar-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $thumb-color: if($is-dark-theme, rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0.2));
  $background-color: if($is-dark-theme, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.1));

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border: 0 none;
    //border-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $thumb-color;
  }

  ::-webkit-scrollbar-thumb:active {
    background: $thumb-color;
  }

  ::-webkit-scrollbar-track {
    background: $thumb-color;
    border: 0 none;
    //border-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

  ::-webkit-scrollbar-track:hover {
    background: $thumb-color;
  }

  ::-webkit-scrollbar-track:active {
    background: $thumb-color;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@mixin saf-search-bar-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .search-bar {
    background-color: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text);
  }
}

@mixin saf-user-panel-theme($theme) {
  $primary: map-get($theme, primary);

  saf-user-panel {
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }
}

@mixin saf-navigation-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  saf-navigation {
    .mat-expansion-panel {
      border-radius: 0;

      &.mat-expanded {
        border-top: 1px solid mat.get-color-from-palette($foreground, divider);
        border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
        color: mat.get-color-from-palette($primary, default);

        .mat-expansion-panel-header-title {
          color: mat.get-color-from-palette($primary, default);
        }
      }

      .mat-expansion-panel-body {
        padding: 0;
      }
    }

    .mat-list-item {
      &:hover,
      &:focus {
        color: white !important;
        background: map_get($primary, default) !important;
      }

      &.active,
      &.active:hover,
      &.active:focus {
        background: map_get($primary, default) !important;
        color: white !important;
      }
    }
  }
}

.deleteIcon {
  color: #cc000d !important;
}

.colorGreen {
  color: green;
}

.colorRed {
  color: red;
}

.printIcon:hover {
  color: $safety-color !important;
}

.copyIcon:hover {
  color: $safety-color !important;
}

.editIcon {
  color: #ecaa61;
}

.mat-dialog-title {
  //-webkit-box-shadow: 0 8px 6px -6px $safety-color;
  //-moz-box-shadow: 0 8px 6px -6px $safety-color;
  //box-shadow: 0 8px 6px -6px $safety-color;
  //text-align: center;
  //padding-bottom: 5px;
}

.mat-card-header {
  text-align: center;
  justify-content: center;
}

mat-card {
  border-radius: 10px;
}

.closeCross {
  position: relative;
  margin-bottom: 10px;

  .cross {
    cursor: pointer;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    color: $safety-color !important;
  }

  .cross:hover {
    color: red !important;
  }
}

@mixin saf-department-select-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  saf-department-select {
    .mat-icon.disabled {
      color: mat.get-color-from-palette($foreground, disabled);
    }
  }

  .department-menu {
    background-color: mat.get-color-from-palette($background, card);
    color: mat.get-color-from-palette($foreground, text);

    .tree-node:hover {
      background: mat.get-color-from-palette($background, hover);
    }
  }
}

@mixin saf-hazard-icon-select-theme($theme) {
  saf-hazard-icon-select {
    &.has-value {
      .mat-form-field-label-wrapper {
        overflow: visible;

        .mat-form-field-label {
          margin-left: -8px;
        }
      }
    }
  }
}

@mixin saf-drag-and-drop-panel-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  saf-drag-and-drop-panel {
    border: 3px dashed mat.get-color-from-palette($foreground, divider);

    &.dragging-file {
      background-color: mat.get-color-from-palette($background, hover);
    }
  }
}

@mixin saf-employee-dialog-theme($theme) {
  .employee-dialog .mat-dialog-container {
    padding-bottom: 0;
  }
}

@mixin saf-hazard-assessment-editor-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  saf-hazard-assessment-editor,
  saf-hazard-assessment-template-editor {
    .header-cell {
      border-top: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .header-cell,
    .classification {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);

      &:first-child {
        border-left: 1px solid mat.get-color-from-palette($foreground, divider);
      }
    }

    .header-cell {
      border-right: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .cell {
      border-right: 1px solid mat.get-color-from-palette($foreground, divider);
      border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
    }

    .measures:hover,
    .add-measure:hover,
    .add-hazard:hover,
    .hazard:hover .cell {
      background-color: mat.get-color-from-palette($background, hover);
      cursor: pointer;
    }

    .group-image {
      filter: invert(if($is-dark-theme, 1, 0));
    }
  }
}

@mixin saf-hazard-icon-dialog-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  saf-hazard-icon-dialog {

    .mat-expansion-panel-body {
      padding: 0 0 16px;
    }

    .mat-list-option {
      width: auto;
    }

    .mat-list-item-content {
      padding: 0 24px !important;
    }
  }
}

@mixin saf-instruction-text-dialog-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  saf-instruction-details-editor-text-dialog {
    .ql-editor {
      height: 500px;
    }
  }
  saf-hazard-assessment-hazard-dialog {
    .ql-editor {
      min-height: 150px;
      max-height: 350px;
    }
  }
}

@mixin saf-notification-menu-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  .notification-menu {
    .notification {
      &:focus,
      &:hover {
        background-color: mat.get-color-from-palette($background, hover);
      }

      &.unseen {
        background-color: rgba(mat.get-color-from-palette($primary, lighter), 0.2);

        &:focus,
        &:hover {
          background-color: rgba(mat.get-color-from-palette($primary, lighter), 0.4);
        }
      }
    }
  }
}

@mixin saf-widget-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);

  .widget {
    .widget-header {
      border-bottom: 1px solid mat.get-color-from-palette($foreground, divider);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  saf-todo-widget {
    .mat-list-item-content {
      padding: 0 8px !important;
    }
  }

}

@mixin saf-file-management-panel-theme($theme) {
  $background: map-get($theme, background);

  saf-file-management-panel {
    .mat-grid-tile {
      background-color: mat.get-color-from-palette($background, card);
    }
  }
}

@mixin quill-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $foreground: map-get($theme, foreground);

  saf-wysiwyg-editor {
    &.required {
      .ql-toolbar,
      .ql-container {
        border-color: $saf-color-red;
      }
    }
  }

  quill-editor {
    .ql-toolbar {
      background-color: mat.get-color-from-palette($foreground, divider);
      border-color: mat.get-color-from-palette($foreground, divider);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .ql-stroke {
        fill: none !important;
        stroke: if($is-dark-theme, white, black) !important;
      }

      .ql-fill {
        fill: if($is-dark-theme, white, black) !important;
        stroke: none !important;
      }

      .ql-picker {
        color: if($is-dark-theme, white, black) !important;
      }

      .ql-picker-options {
        background: if($is-dark-theme, grey, white) !important;
      }

      .ql-color-label {
        background: black !important;
      }

      .ql-picker-label {
        color: if($is-dark-theme, white, black) !important;
      }
    }

    .ql-toolbar button:hover .ql-stroke {
      fill: none !important;
      stroke: $safety-color !important;
    }

    .ql-toolbar button:hover .ql-fill {
      fill: $safety-color !important;
      stroke: none !important;
    }

    .ql-toolbar button:hover .ql-picker {
      color: $safety-color !important;
    }

    .ql-toolbar .ql-picker-label:hover {
      color: $safety-color !important;
    }

    .ql-toolbar .ql-picker-item:hover {
      color: $safety-color !important;
    }

    .ql-toolbar button.ql-active .ql-stroke {
      fill: none !important;
      stroke: $safety-color !important;
    }

    .ql-toolbar button.ql-active .ql-fill {
      fill: $safety-color !important;
      stroke: none !important;
    }

    .ql-toolbar button.ql-active .ql-picker {
      color: $safety-color !important;
    }

    .ql-toolbar .ql-picker-item.ql-selected {
      color: $safety-color !important;
    }

    .ql-container {
      border-color: mat.get-color-from-palette($foreground, divider);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      //height: 150px !important;


      .ql-blank {

      }

      .ql-blank:before {
        text-align: left;
        font-size: inherit;
        font-family: Roboto, "Helvetica Neue", sans-serif;
        color: rgb(122, 122, 122);
        line-height: 20px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .ql-editor:hover {
      outline: if($is-dark-theme, 2px solid white, 2px solid black) !important;
      border-radius: 2px;
    }

    .ql-editor {
      border: 2px solid transparent !important;
      font-size: 14px;

      p {
        margin: 0;
      }
    }
  }
}

@mixin angular-tree-theme($theme) {
  $background: map-get($theme, background);

  .angular-tree-component {
    .node-wrapper:hover,
    .node-content-wrapper-active {
      background: mat.get-color-from-palette($background, hover);
      box-shadow: none;
    }

    .node-content-wrapper {
      flex-grow: 1;
      position: relative;
      padding: 4px 8px;
      line-height: 40px;

      &:hover {
        background: none;
        box-shadow: none;
      }
    }

    //.toggle-children-wrapper {
    //  height: 48px;
    //  width: 40px;
    //  padding: 0;
    //  display: block;
    //}

    .tree-children {
      padding-left: 40px;
    }

    .toggle-children {
      //top: 20px;
      display: block;
      margin: 0 auto;
    }

    //.toggle-children-placeholder {
    //  display: none;
    //}
    //
    //.tree-node-loading {
    //  display: none;
    //}

    .pull-right {
      float: right;
    }
  }
}

@mixin saf-observation-dialog-theme($theme) {
  saf-observation-dialog {
    saf-file-management-panel {
      .container {
        box-shadow: 0 0 0 0 !important;
        margin: 0 -16px;
      }
    }
  }
}

@mixin saf-material-theme($theme) {
  @include saf-core-theme($theme);
  @include saf-autofill-theme($theme);
  @include saf-scrollbar-theme($theme);
  @include saf-search-bar-theme($theme);
  @include saf-user-panel-theme($theme);
  @include saf-navigation-theme($theme);
  @include saf-employee-dialog-theme($theme);
  @include saf-department-select-theme($theme);
  @include saf-hazard-icon-select-theme($theme);
  @include saf-drag-and-drop-panel-theme($theme);
  @include saf-hazard-assessment-editor-theme($theme);
  @include saf-hazard-icon-dialog-theme($theme);
  @include saf-instruction-text-dialog-theme($theme);
  @include saf-notification-menu-theme($theme);
  @include saf-widget-theme($theme);
  @include saf-file-management-panel-theme($theme);
  @include saf-observation-dialog-theme($theme);
}

@mixin vendor-material-theme($theme) {
  @include quill-theme($theme);
  @include angular-tree-theme($theme);
}

@mixin saf-core() {
  @include mat.core();

  @each $class, $theme in $saf-themes {
    .#{$class} {
      @include mat.all-component-themes($theme);
      @include saf-material-theme($theme);
      @include vendor-material-theme($theme);
    }
  }

  .mat-button,
  .mat-flat-button,
  .mat-raised-button,
  .mat-stroked-button {
    border-radius: 10px!important;
    min-width: auto!important;
    height: 40px!important;

    .mat-button-wrapper {
      .mat-icon {
        //width: 18px;
        //height: 18px;
        //font-size: 20px;
        //line-height: 18px;

        &:not(:only-child) {
          margin-left: -4px;
          margin-right: 8px;
        }
      }
    }
  }
  .mat-fab .mat-button-wrapper {
    padding: 0 !important;
    display: inline-block;
    line-height: 24px;
  }
  .mat-mini-fab .mat-button-wrapper {
    padding: 0 !important;
    display: inline-block;
    line-height: 24px;
  }
  .mat-dialog-container {
    position: relative;
    border-radius: 10px !important;
  }

  .mat-card-header.no-text-margin .mat-card-header-text {
    margin: 0 !important;
  }

  .block-fields {
    .mat-form-field:not(.size-auto),
    .mat-slide-toggle:not(.size-auto),
    .mat-checkbox:not(.size-auto),
    .mat-radio-button:not(.size-auto) {
      display: block;
      width: 100%;

      .mat-checkbox-layout {
        white-space: normal !important;
      }
    }
  }

  .mat-form-field.no-hint {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .hidden {
    display: none;
  }

  .spacer {
    flex: 1 1 auto;
  }

  .saf-sidenav {
    border: 0 none !important;
  }

  .multi-line-tooltip {
    white-space: pre-line;
  }

  .table-dialog {
    max-width: 960px;
    width: 100vw;
    box-sizing: border-box;

    @media(max-width: 1000px) {
      width: 95vw;
    }
  }

  .small-dialog {
    max-width: $saf-max-content-width;
    box-sizing: border-box;
    width: 35vw;

    @media($saf-xlarge) {
      width: 25vw;
    }

    @media($saf-medium) {
      width: 40vw;
    }

    @media(max-width: 959px) {
      width: 50vw;
    }

    @media(max-width: 599px) {
      width: 95vw;
    }
  }

  .medium-dialog {
    max-width: $saf-max-content-width;
    box-sizing: border-box;
    width: 50vw;

    @media(max-width: 959px) {
      width: 65vw;
    }

    @media(max-width: 599px) {
      width: 95vw;
    }
  }

  .large-dialog {
    max-width: $saf-max-content-width;
    box-sizing: border-box;
    width: 75vw;

    @media(max-width: 959px) {
      width: 95vw;
    }

    @media(max-width: 599px) {
      width: 95vw;
    }
  }

  .large-preview-dialog {
    max-width: $saf-max-content-width;
    box-sizing: border-box;
    width: 75vw;
    height: 90vh;

    @media(max-width: 959px) {
      width: 95vw;
    }

    @media(max-width: 599px) {
      width: 95vw;
    }
  }

  .filter-table-dialog {
    max-width: $saf-max-content-width;
    box-sizing: border-box;
    width: 75vw;
    height: 90vh;

    @media(max-width: 959px) {
      width: 95vw;
    }

    @media(max-width: 599px) {
      width: 95vw;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-reverse {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }

  .mat-icon {
    &.spinning {
      transform-origin: center center;
      animation: 1s linear 0s spin infinite;
    }

    &.spinning-reverse {
      transform-origin: center center;
      animation: 1s linear 0s spin-reverse infinite;
    }
  }

  .accident {
    //border:5px rgba(210,16,16,0.95) solid!important;
    //background-color: rgba(210,16,16,0.95)!important;
    //border: 2px #1f6533 solid !important;
    background-color: #1f6533 !important;

  }

  .medical {
    //border:5px rgba(210,16,16,0.95) solid!important;
    //background-color: rgba(210,16,16,0.95)!important;
    //border: 2px #1f6533 solid !important;
    background-color: #1f6533 !important;

  }

  .accidentBook {
    //border: 2px rgba(210, 16, 16) solid !important;
    background-color: rgba(210, 16, 16) !important;
  }

  .administration, .userAdministration {
    //border:5px rgba(33,163,110,0.95) solid!important;
    //background-color: rgba(33,163,110,0.95)!important;
    //border: 2px $safety-color solid !important;
    background-color: $safety-color !important;
  }


  .observation {
    //border: 2px rgba(97, 165, 48) solid !important;
    background-color: rgba(97, 165, 48) !important;
  }

  .checklist {
    //border: 2px rgba(33, 112, 196) solid !important;
    background-color: rgba(33, 112, 196) !important;
  }
  .hazardousSubstance {
    //border: 2px rgb(33,147,30) solid !important;
    background-color: rgb(33,147,30) !important;
  }

  .audit {
    //border: 2px rgba(33, 112, 196) solid !important;
    background-color: rgba(33, 112, 196) !important;
  }
  .hazardAssessment {
    //border: 2px rgba(212, 185, 15) solid !important;
    background-color: rgba(212, 185, 15) !important;
  }

  .instruction {
    //border: 2px rgba(226, 110, 18) solid !important;
    background-color: rgba(226, 110, 18) !important;
  }

  .externalCompany, .externalCompanies {
    //border: 2px rgba(123, 122, 123) solid !important;
    background-color: rgba(123, 122, 123) !important;
  }

  .measure {
    //border: 2px rgba(193, 62, 156) solid !important;
    background-color: rgba(193, 62, 156) !important;
  }

  .measurement {
    //border: 2px rgba(193, 62, 156) solid !important;
    background-color: rgba(193, 62, 156) !important;
  }

  .schooling {
    //border: 2px rgb(113, 212, 255) solid !important;
    background-color: rgb(113, 212, 255) !important;
  }

  .qualification {
    //border: 2px rgb(87, 220, 20) solid !important;
    background-color: rgb(87, 220, 20) !important;
  }

  .screening {
    //border: 2px rgb(58, 120, 150) solid !important;
    background-color: rgb(58, 120, 150) !important;
  }

  .controlling {
    //border: 2px rgb(58, 120, 150) solid !important;
    background-color: rgb(129, 8, 194) !important;
  }

  .deleteButtonHover:hover {
    z-index: 10 !important;
    background-color: rgba(206, 34, 34, 0.95) !important;
  }

  .saveButtonHover:hover {
    z-index: 10 !important;
    background-color: rgba(21, 121, 22, 0.95) !important;
  }

  .saveCloseButtonHover:hover {
    z-index: 10 !important;
    background-color: rgba(21, 121, 22, 0.95) !important;
  }

  .maintenance, .inspection {
    //border: 2px rgb(162, 167, 159) solid !important;
    background-color: rgb(162, 167, 159) !important;
  }

  .isCheckIcon {
    color: $saf-color-green;
  }

  .isVisibilityIcon {
    color: rgb(198, 114, 237) !important;
  }

  .isAccessTimeIcon {
    color: rgb(37, 324, 245) !important;
  }

  .isClearIcon {
    color: $saf-color-red;
  }

  //.mat-sort-header-arrow.ng-trigger.ng-trigger-arrowPosition {
  //  opacity: 0.5 !important;
  //}

  //.mat-sort-header-arrow {
  //  color: rgb(232, 47, 47) !important;
  //}

  .mat-sort-header-sorted {
    //color: rgb(5, 45, 104)!important; __<<ngThemingMigrationEscapedComment2>>__
  }

  .mat-sort-header-arrow {
    color: rgb(255, 0, 0) !important;
  }
}

::ng-deep .mat-sort-header-container:not(.mat-sort-header-sorted) .mat-sort-header-arrow {
  opacity: 1 !important;
  color: $safety-color !important;
  //filter: saturate(0%) brightness(70%) contrast(1000%);
  transform: translateY(0px) !important;
}

::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $safety-color;
}
//
//::ng-deep .mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
//  //background-color: rgba(19, 176, 176, 1) !important;
//  background-color: rgb(244, 255, 118) !important;
//}

.iconColor {
  color: $safety-color !important;

  //.mat-icon-button {
  //  color: $safety-color !important;
  //}
}

.addIconSelect {
  border-radius: 50%;
  background-color: $safety-color;
}

.disabledIcon {
  color: #7e7d7e;
}

::ng-deep .mat-select-arrow {
  //color: $safety-color !important;
}

.safetyOfficer {
  color: #a94787 !important;
}

.safetyDelegate {
  color: #b98de1 !important;
}

.worksCouncilMember {
  color: #c0cb15 !important;
}

.companyDoctor {
  color: #2a8854 !important;
}

.firePreventionHelper {
  color: #cb4612 !important;
}

.firstAider {
  color: #72be94 !important;
}

.responsible {
  color: #6685dd !important;
}

.employee {
  color: $safety-color !important;
}

.safFilter {
  flex: 1;
  font-size: 11px !important;
}

.filterMargin {
  margin: 0 8px !important;
}

.oneFilter {
  //margin: 0 8px !important;
  max-width: 100%;
  font-size: 11px !important;
}

.twoFilter {
  //margin: 0 8px !important;
  max-width: 50%;
  font-size: 11px !important;
}

.fourFilter {
  //margin: 0 8px !important;
  max-width: calc(25% - 8px);
  font-size: 11px !important;
}

.threeFilter {
  //margin: 0 8px !important;
  max-width: calc(25% - 33.3333333333333333333%);
  font-size: 11px !important;
}

::ng-deep .mat-expansion-indicator:after {
  color: $safety-color !important;
}

::ng-deep .mat-tooltip {
  /* your own custom styles here */
  /* e.g. */
  background-color: rgba(0, 0, 0, 0.68) !important;
  //color: $safety-color!important;
}

//.table-container {
//  max-height: calc(100vh - 315px) !important;
//  //max-height: 59vh !important;
//  overflow: auto;
//  border-radius: 10px;
//}

.sectionWithoutButtons {
  height: 41px;
}

::ng-deep.mat-pseudo-checkbox {
  //display: none !important;
}

.mat-select-panel {
  //overflow: hidden !important;
}

footer {
  border-top: 2px solid gray;
  height: 44px;
  padding-top: 10px;
  padding-right: 10px;
  text-align: right;
}

.multi-select-panel {
  max-height: 200px; // mat-select-panel height = 256px. 200 + 10 + 44 + 2
  overflow: auto;
}

.checkbox-icon {
  vertical-align: middle;
  margin-right: 8px;
  margin-left: 10px !important;
}

.theme-dark .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgb(70, 70, 70);
}


.mat-expansion-padding {
  margin-bottom: 15px
}

.hasEmailIcon {
  color: #0e8906;
}

.theme-light .mat-form-field-label,
.theme-light .mat-hint {
  color: #000c!important;
}

.theme-light .mat-paginator,
.theme-light .mat-paginator-size .mat-select-trigger {
  color: #000000!important;
}

.status-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  vertical-align: middle;
  display: inline-block;

  &.red {
    background-color: $saf-color-red;
  }

  &.yellow {
    background-color: $saf-color-yellow;
  }

  &.green {
    background-color: $saf-color-green;
  }

  &.grey {
    background-color: $saf-color-grey;
  }

  &.blue {
    background-color: cornflowerblue;
  }
}

.status-color-dropdown {

  &.red {
    background-color: $saf-color-red;
  }

  &.yellow {
    background-color: $saf-color-yellow;
  }

  &.green {
    background-color: $saf-color-green;
  }

  &.grey {
    background-color: $saf-color-grey;
  }
}

.tableUnfold {
  width: 50%;
}
.tableFold {
  display: none;
}
.viewUnfold {
  //width: 640px;
  width: 50%;
}
.viewFold {
  //width: 1280px;
  width: 100%;
}
.foldButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #424242;
  border-radius: 10px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  cursor: pointer;
}
.mobile-widget-card {
  padding: 10px 0 0 0;
}
.widget-card {
  box-shadow: none!important;
}
.menu-icon-div {
  display: flex;
  position: relative;
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  box-sizing: border-box;
  user-select: none;
  p {
    padding: 10px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }
  .navIcon {
    color: white;
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 100px;
    font-size: 100px;
    padding: 5px;
  }
  .navIconText {
    color: white;
  }
  .addNavIcon {
    color: white;
    position: absolute;
    width: 40px;
    height: 40px;
    font-size: 40px;
    right: 5px;
    top: 60px;
    border-radius: 50%;
  }
}

.avatar-area {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  height: 160px;
  background-color: #c5c5c5;
  border-radius: 15px;
  object-fit: cover;
  position: relative;
  input {
    height: 0;
    width: 0;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 15px;
  }
  &:hover {
    .avatar-upload {
      opacity: 1;
    }
  }
  .avatar-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    mat-icon {
      width: 100px;
      height: 100px;
      font-size: 100px;
    }
  }
  .avatar-upload {
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    top: 0;
    transition: .2s cubic-bezier(0.4, 0, 1, 1);
  }
  .avatar-upload-icon {
    width: 45px;
    height: 45px;
    font-size: 45px;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      color: rgb(227, 227, 227);
    }
  }
  .avatar-delete-icon {
    position: absolute;
    bottom: 10px;
    width: 30px;
    height: 30px;
    font-size: 30px;
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
      color: rgb(250, 31, 31);
    }
  }
}

.hex-container {
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 90%;
  user-select: none;

  p {
    position: absolute;
    top: 175px;
    margin: 0;
    width: 90%;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
  }

  .hexagon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 95px;
    border-radius: 18px / 22px;
    margin-top: 40px;
  }

  .hexagon:before,
  .hexagon:after {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
    content: '';
  }

  .hexagon:before {
    transform: rotate(60deg);
  }

  .hexagon:after {
    transform: rotate(-60deg);
  }

  .hex-icon-div {
    display: flex;
    justify-content: center;
    align-items: center;

    .hexIcon {
      color: white;
      height: 90px;
      width: 90px;
      font-size: 90px;
      z-index: 1;
    }
    .addNavIcon {
      color: white;
      position: absolute;
      z-index: 1;
      right: 5px;
      top: 3px;
    }
  }
}

.hexagon-small {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 22px;
  margin: 10px 0;
  border-radius: 4px / 6px;
}

.hexagon-small:before,
.hexagon-small:after {
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: inherit;
  content: '';
}

.hexagon-small:before {
  transform: rotate(60deg);
}

.hexagon-small:after {
  transform: rotate(-60deg);
}

.hex-icon-div-small {
  display: flex;
  justify-content: center;
  align-items: center;

  .hexIcon {
    color: white;
    height: 20px;
    width: 20px;
    font-size: 20px;
    z-index: 1;
  }

  .addNavIcon {
    color: white;
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    height: 10px;
    width: 10px;
    font-size: 10px;
  }
}

.container {
  max-width: 100%;

  .header {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    align-items: center;
  }

  .content {
    padding: 0;

    .table-container {
      max-height: calc(100vh - 155px) !important;
      overflow: auto;
      border-radius: 10px;
    }
  }
}

.dialog-table-container {
  max-height: 60vh !important;
  overflow: auto;
  border-radius: 10px;
}

.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.mat-accordion .mat-expansion-panel:first-of-type {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

::ng-deep .mat-content {
  height: 100%;
  align-items: center;
}
